import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { PersistGate } from "redux-persist/es/integration/react";
import { BrowserRouter } from 'react-router-dom';
const App = React.lazy(()=>import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.Suspense fallback={<React.Fragment/>}>
     <BrowserRouter>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <App />  
            </PersistGate>
        </Provider>
    </BrowserRouter>
   </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
